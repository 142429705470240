import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import {
  FieldPhoneNumberInput,
  FieldSelect,
  FieldTextInput,
  Form,
  PrimaryButton,
} from '../../../../components';
import { FormattedMessage, injectIntl, intlShape } from '../../../../util/reactIntl';
import * as validators from '../../../../util/validators';
import css from './RenderQuestionForm.module.css';

const filterOptions = [
  { key: 'host', label: 'Host' },
  { key: 'renter', label: 'Renter' },
  { key: 'both', label: 'Both' },
];

const renterOptions = [
  { key: 'a_few_weekends_per_month', label: 'A few times/weekends per month' },
  { key: 'a_few_weeks_per_year', label: 'A few weeks per year ' },
  { key: 'a_few_months_per_year', label: 'A few months per year' },
  {
    key: 'only_during_holidays_community_events',
    label: 'Only during holidays/community golf cart events',
  },
];

const hostOptions = [
  { key: 'as_much_as_possible', label: 'As much as possible' },
  { key: 'a_few_times_per_month', label: 'A few times per month' },
  { key: 'a_few_times_per_year', label: 'A few times per year' },
  {
    key: 'snowbird',
    label: 'I am a snowbird or away much of the year and willing to rent it 4-6 months a year',
  },
];

const QuestionFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const { handleSubmit, inProgress, intl, invalid, values, form } = fieldRenderProps;

      // email
      const emailRequiredMessage = intl.formatMessage({
        id: 'LoginForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'LoginForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      const submitDisabled = invalid || inProgress;
      return (
        <Form
          onSubmit={e => {
            e.preventDefault();
            handleSubmit(form);
          }}
        >
          <div className={css.questionForm}>
            <div className={css.formHeading}>
              <FormattedMessage id="SectionHero.receiveLaunch" />
            </div>
            <div className={css.row}>
              <FieldTextInput
                className={css.inputBox}
                type="text"
                id={'1001'}
                name="name"
                label={'Full Name'}
                placeholder={'Your full name'}
                validate={validators.required(
                  intl.formatMessage({
                    id: 'LoginForm.emailRequired',
                  })
                )}
              />

              <FieldTextInput
                className={css.inputBox}
                type="email"
                id={'1002'}
                name="email"
                autoComplete="email"
                label={'Email'}
                placeholder={'Your Email'}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />
            </div>
            <div className={css.row}>
              <FieldPhoneNumberInput
                className={css.inputBox}
                name="phoneNumber"
                id={'1003'}
                label={'Phone Number'}
                placeholder={'Your phone number'}
              />

              <FieldTextInput
                className={css.inputBox}
                type="text"
                id="community"
                name="community"
                label={'Community/Neighborhood'}
                placeholder={'Name of Community/Neighborhood if applicable'}
              />
            </div>
            <div className={css.row}>
              <FieldTextInput
                className={css.inputBox}
                type="text"
                id="city"
                name="city"
                label={'City'}
                placeholder={'Your city'}
                validate={validators.required(
                  intl.formatMessage({
                    id: 'LoginForm.emailRequired',
                  })
                )}
              />

              <FieldTextInput
                className={css.inputBox}
                type="text"
                id="state"
                name="state"
                label={'State'}
                placeholder={'Your state'}
                validate={validators.required(
                  intl.formatMessage({
                    id: 'LoginForm.emailRequired',
                  })
                )}
              />
            </div>

            <FieldSelect
              className={css.inputBox}
              name={'userType'}
              label="User Type"
              id={'1004'}
              validate={validators.required(
                intl.formatMessage({
                  id: 'LoginForm.emailRequired',
                })
              )}
            >
              <option disabled value="">
                {'Would you like to become a:'}
              </option>
              {filterOptions.map(optionConfig => {
                const { key } = optionConfig;
                return (
                  <option key={key} value={key}>
                    {optionConfig.label}
                  </option>
                );
              })}
            </FieldSelect>
            <div className={values.userType === 'both' ? css.row : css.fullInput}>
              {(values.userType === 'renter' || values.userType === 'both') && (
                <FieldSelect
                  className={css.inputBox}
                  name={'renter'}
                  label="Renter"
                  id={'1005'}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'LoginForm.emailRequired',
                    })
                  )}
                >
                  <option disabled value="">
                    {
                      'As a RENTER, how often would you rent a golf cart from a neighbor or local business? '
                    }
                  </option>
                  {renterOptions.map(optionConfig => {
                    const { key } = optionConfig;
                    return (
                      <option key={key} value={key}>
                        {optionConfig.label}
                      </option>
                    );
                  })}
                </FieldSelect>
              )}
              {(values.userType === 'host' || values.userType === 'both') && (
                <FieldSelect
                  className={css.inputBox}
                  label="Host"
                  name={'host'}
                  id={'1006'}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'LoginForm.emailRequired',
                    })
                  )}
                >
                  <option disabled value="">
                    {'As a HOST, how often would you want to rent your golf cart?'}
                  </option>
                  {hostOptions.map(optionConfig => {
                    const { key } = optionConfig;
                    return (
                      <option key={key} value={key}>
                        {optionConfig.label}
                      </option>
                    );
                  })}
                </FieldSelect>
              )}
            </div>

            <FieldTextInput
              className={css.inputBox}
              label="Comments/Feedback"
              id={'feedback'}
              name="feedback"
              type="textarea"
              placeholder={'Comments/Feedback'}
              validate={validators.required(
                intl.formatMessage({
                  id: 'LoginForm.emailRequired',
                })
              )}
            />

            <PrimaryButton
              className={css.submitButton}
              type="submit"
              disabled={submitDisabled}
              inProgress={inProgress}
            >
              Submit
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

QuestionFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  form: null,
  inProgress: false,
};

const { string, bool } = PropTypes;

QuestionFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  form: string,
  inProgress: bool,
  intl: intlShape.isRequired,
};

const RenderQuestionForm = compose(injectIntl)(QuestionFormComponent);

RenderQuestionForm.displayName = 'QuestionForm';

export default RenderQuestionForm;
